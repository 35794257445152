import React, { useEffect, useContext, useState } from "react";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";

import "./dialog.scss";
import { Dialog } from "../shared";
import agreeIcon from "../../styles/images/inbox-agree.png";
import disagreeIcon from "../../styles/images/inbox-disagree.png";
import tipsIcon from "../../styles/images/inbox-tips.png";
import memberInboxStore from "../../stores/memberInboxStore";
import { wxJsPay, debugFn } from "../../utils/wx_tools";
import AuthStore from "../../stores/authStore";
import { fetchMemberInfoApi, unlockMemberApi } from "../../api/index";
import { buyInboxMemberEvent } from "../../utils/ga_events";
import checkOrigin from "./checkOrigin";
// import images from "./images";
import { onAllImgLoaded } from "../../utils/tools";
import { isWxLogin } from "../../utils/wx_tools";
import { message } from "antd";

interface Props {
  onSuccess?(): any;
}

// 新用户购买弹窗
const NewBuyDialog: React.FC<Props> = (props: Props) => {
  const { onSuccess = () => {} } = props;
  const { id } = useParams();
  const { fetchMemberInfo, isCurrentUserEmpty, memberInfo } = useContext(
    AuthStore
  );
  const {
    dialog,
    setDialog,
    xy,
    xyText,
    wxLogin,
    // ntmId,
    noyId,
    setPurchase,
  } = useContext(memberInboxStore);
  const [goods, setGoods] = useState<any>([
    {
      id: noyId,
      info: {},
      desc: "一年会员",
    },
    {
      id: noyId,
      info: {},
      desc: "一年会员",
    },
  ]);
  const [good, setGood] = useState<any>(goods[0]);
  const [show, setShow] = useState<any>(false);
  const [agree, setAgree] = useState<any>(true);
  const [right, setRight] = useState(12);
  const [width, setWidth] = useState(345);
  const purchase_source = checkOrigin();

  const fetchInfo = async () => {
    //!todo: 因为后端接口并发会导致卡死,等后端修复后恢复成promise.all();
    await fetchMemberInfoApi(goods[0].id).then((res: any) => {
      goods[0].src = res.show_image_url;
      goods[0].info = res;
      setGoods(goods);
    });
    await fetchMemberInfoApi(goods[1].id).then((res: any) => {
      goods[1].src = res.show_image_url;
      goods[1].info = res;
      setGoods(goods);
    });
  };

  useEffect(() => {
    setGood(goods[0]);
    setShow(false);
    if (dialog["new-buy"]) {
      fetchInfo().then(() => {
        onAllImgLoaded(document.querySelector(".new-buy-dialog")).then(() => {
          setShow(true);
        });
      });
      setRight(
        document.querySelector(".new-buy-dialog")
          ? (document.body.clientWidth -
              document.querySelector(".new-buy-dialog").getBoundingClientRect()
                .width) /
              2 +
              12
          : 12
      );
      setWidth(
        document.querySelector(".new-buy-dialog").getBoundingClientRect().width
      );
    }
  }, [dialog["new-buy"]]);

  const isLogin = (cb: () => void) => {
    memberInfo && isWxLogin() ? cb() : wxLogin();
  };

  const onCancel = () => {
    isLogin(() => {
      window.localStorage.removeItem("gangLogin");
      setDialog("new-buy", false);
    });
  };

  return (
    <Dialog
      rootStyle={{
        position: "fixed",
        width: "100%",
        height: "100%",
        overflowY: "auto",
        zIndex: 1,
      }}
      layerStyle={{
        zIndex: -2,
      }}
      style={{
        width,
        padding: "0",
        borderRadius: "10px",
        backgroundColor: "transparent",
        position: "unset",
        transform: "unset",
        margin: "0 auto",
        marginTop: "20px",
        overflow: "hidden",
      }}
      visible={dialog["new-buy"]}
      onCancel={onCancel}
      layerClose={false}
      closeStyle={{
        color: "rgba(55,65,81,0.2)",
        top: "40px",
        right,
      }}
    >
      <div className={`new-buy-dialog ${show && "new-buy-dialog--show"}`}>
        <div className="cost-dialog__header">
          <img src={good.src} alt="" />
          {/* <div className="new-buy-tap__wrapper">
            <div
              className="new-buy-tap new-buy-tap--1"
              onClick={() => {
                setGood(goods[0]);
              }}
            />
            <div
              className="new-buy-tap new-buy-tap--2"
              onClick={() => {
                setGood(goods[1]);
              }}
            />
          </div> */}
        </div>
        <div className="cost-dialog__body">
          <div
            className="cost-dialog__btn"
            onClick={() => {
              if (isCurrentUserEmpty && !memberInfo) {
                wxLogin();
              } else {
                if (agree) {
                  onCancel();
                  wxJsPay(good.id)
                    .then(async (data: any) => {
                      if (id) {
                        await unlockMemberApi({
                          id,
                        });
                      }
                      // 标记是刚购买过的会员
                      setPurchase("newMember");
                      fetchMemberInfo();
                      setDialog("pay-success", true);
                      onSuccess();
                      buyInboxMemberEvent({
                        transaction_id: data.shortId,
                        value: data.final_price / 100,
                        item_id: data.proProductId,
                        item_name: data.proProductName,
                        price: data.final_price / 100,
                        purchase_source,
                        user_id: memberInfo.id,
                        user: memberInfo.username,
                      });
                    })
                    .catch((err: any) => {
                      setDialog("pay-error", true, {
                        name: "new-buy",
                      });
                      debugFn(err);
                    });
                } else {
                  message.open({
                    type: "error",
                    duration: 3,
                    className: "ant-toast",
                    content: xyText,
                  });
                }
              }
            }}
          >
            立即订阅
          </div>
          <div
            className="cost-tips"
            style={good.info.desc ? {} : { marginBottom: "20px" }}
          >
            <span onClick={() => isLogin(() => setAgree(!agree))}>
              <img src={agree ? agreeIcon : disagreeIcon} alt="agreeIcon" />
              我已阅读并同意{" "}
            </span>
            <span>
              <a href={xy} target="_blank">
                《用户服务协议》
              </a>
            </span>
          </div>
          {good.info.desc && (
            <div className="cost-intro">
              <div className="cost-intro__title">
                <img src={tipsIcon} alt="agreeIcon" />
                权益详细说明
              </div>
              <div className="cost-intro__desc">
                {good.info.desc
                  .split("\r\n")
                  .map((p: string, index: number) => (
                    <p key={index}>{p}</p>
                  ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default observer(NewBuyDialog);
